<template>
  <div>
    <back-btn />
    <v-container fluid>
      <v-card class="card--shadow">
        <v-card-text>
          <filter-form @emitDate="getForm7" />
          <div class="d-flex justify-end" v-show="twoDate">
            <export-form-7 v-if="twoDate" :twoDate="twoDate" />
          </div>
        </v-card-text>

        <v-data-table
          :headers="headers"
          :items="datas"
          :loading="loading"
          item-key="id"
          hide-default-footer
          hide-default-header
          :disable-pagination="true"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead class="header">
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.text"
                  class="header--text"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="{ index, item }">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ item.patient.name }}</td>
              <td>{{ calculateAge(item.patient.dob) }}</td>
              <td>{{ item.patient.sex == 1 ? "M" : "F" }}</td>
              <td>
                {{ item.patient.mdrtb_reg_no }}/{{
                  item.patient.mdrtb_reg_year
                }}
              </td>
              <td>
                <span v-if="item.type_info == `PREENROLL`">New Patient</span>
                <span
                  v-else-if="
                    item.patient.finished_date || item.patient.final_outcome
                  "
                >
                  <span
                    v-if="
                      new Date(item.date) < new Date(item.patient.finished_date)
                    "
                    >On-going</span
                  >
                  <span v-else>
                    <span v-if="item.patient.treatment_finish == 1">
                      <span v-if="item.patient.final_outcome == 1">Cured</span>
                      <span v-if="item.patient.final_outcome == 2"
                        >Completed</span
                      >
                      <span v-if="item.patient.final_outcome == 3">Died</span>
                      <span v-if="item.patient.final_outcome == 4"
                        >Lost to follow up</span
                      >
                      <span v-if="item.patient.final_outcome == 5">Failed</span>
                      <span v-if="item.patient.final_outcome == 6"
                        >Moved to XDR</span
                      >
                      <span v-if="item.patient.final_outcome == 7"
                        >Not evaludated/ Trasfer Out</span
                      >
                      <span v-if="item.patient.final_outcome == 8"
                        >Move to resident township</span
                      >
                    </span>
                    <span v-else-if="item.patient.treatment_finish == 2"
                      >Treatment Finish</span
                    >
                  </span>
                </span>
                <span v-else>On-going</span>
              </td>
              <td>{{ item.payment }}</td>
              <td>
                <span v-if="item.type_info == `PREENROLL`">Cash</span>
                <span v-else-if="item.type == 1">Cash</span>
                <span v-else-if="item.type == 2">ATM</span>
                <span v-else-if="item.type == 3">Mobile</span>
              </td>
              <td>{{ item.date }}</td>
              <td>
                <span v-if="item.final_remark">{{ item.final_remark }}</span>
                <span v-else-if="item.type_info == `PREENROLL`"
                  >New Patient</span
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ExportForm7 from "@/components/report/form7/ExportForm7";
import FilterForm from "@/components/report/form7/FilterForm";
import BackBtn from "@/components/navigation/BackBtn";
import { CalculateAge } from "./../../mixins/CalculateAge";
// import moment from 'moment'
export default {
  mixins: [CalculateAge],
  components: {
    ExportForm7,
    FilterForm,
    BackBtn,
  },
  computed: {
    ...mapState("Report", ["datas"]),
    ...mapState("Loading", ["loading"]),
  },
  data: () => ({
    pageCount: 0,
    page: 1,
    twoDate: null,
    headers: [
      { text: "No", value: "no" },
      { text: "Name", value: "name" },
      { text: "Age", value: "dob" },
      { text: "Sex", value: "sex" },
      { text: "DR-TB Code", value: "mdrtb_code" },
      { text: "Treatment Status", value: "treatment_status" },
      { text: "Amount", value: "amount" },
      { text: "Payment Channel", value: "payment_channel" },
      { text: "Date of Payment", value: "date_of_payment" },
      { text: "Remark", value: "remark" },
    ],
  }),
  methods: {
    getForm7(twoDate) {
      if (twoDate.startDate && twoDate.endDate) {
        this.twoDate = twoDate;
        this.$store.dispatch("Report/getForm7", twoDate);
      }
    },
  },
  created() {
    this.$store.dispatch("Report/resetState");
  },
};
</script>

<style></style>
