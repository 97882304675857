<template>
  <export-excel
    class="export_btn"
    :title="title"
    :fields="custom_fields"
    :fetch="getData"
    type="csv"
    :footer="footer"
    :name="`FORM 7 Export from ${twoDate.startDate} to ${twoDate.endDate}.xls`"
  >
    <v-icon color="white" left>mdi-chevron-right-circle-outline</v-icon>Export
  </export-excel>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    twoDate: {
      type: Object,
      default: () => {
        return {
          startDate: null,
          endDate: null,
        };
      },
    },
  },
  computed: {
    ...mapState("Admin", ["admin"]),
    ...mapState("Report", ["datas"]),
    title() {
      return [
        "So cio-economic support (Nutrition and Travelling allowance) and Pre-enrollment support payment for DR-TB patient under (Community Based DR-TB Care project) implemented by The Union collaboration with NTP (DR-TB Form 7)",
        `Payment period:${this.twoDate.startDate}-${this.twoDate.endDate}` +
          Array(150).fill(" ").join("") +
          `Township:${this.admin.township.name}`,
      ];
    },
    footer() {
      const addSpace = Array(70).fill(" ").join("");
      const signSpace = Array(80).fill(" ").join("");
      const nameSpace = Array(75).fill(" ").join("");
      const dsgSpace = Array(67).fill(" ").join("");
      return [
        [
          `Prepared By${addSpace}` +
            `Verified By${addSpace}` +
            `Acknowledged By${addSpace}`,
        ],
        [`Sign:${signSpace}` + `Sign:${signSpace}` + `Sign:${signSpace}`],
        [`Name: ${nameSpace}` + `Name:${nameSpace}` + `Name:${nameSpace}`],
        [
          `Designation:${dsgSpace}` +
            `Designation:${dsgSpace}` +
            `Designation:${dsgSpace}`,
        ],
      ];
    },
  },
  data: () => ({
    custom_fields: {
      Name: "patient.name",
      Date: "date",
      Age: {
        field: "patient.dob",
        callback: (value) => {
          let currentDate = new Date();
          let birthDate = new Date(value);
          let age = currentDate.getFullYear() - birthDate.getFullYear();
          let month = currentDate.getMonth() - birthDate.getMonth();
          if (
            month < 0 ||
            (month === 0 && currentDate.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          return age;
        },
      },
      "DR-TB CODE": {
        field: "patient",
        callback: (value) => {
          return `${value.mdrtb_reg_no}/${value.mdrtb_reg_year}`;
        },
      },
      "Treatment Status": {
        callback: (value) => {
          if (value.type_info == `PREENROLL`) {
            return "New Paitent";
          } else if (
            value.patient.finished_date ||
            value.patient.final_outcome
          ) {
            return "Finish";
          } else {
            return "On-going";
          }
        },
      },
      Amount: "payment",
      "Payment Channel": {
        callback: (value) => {
          if (value.type_info == `PREENROLL`) return "Cash";
          else if (value.type == 1) return "Cash";
          else if (value.type == 2) return "ATM";
          else if (value.type == 3) return "Mobile";
        },
      },
      "Date of Payment": "date",
      Remark: {
        callback: (value) => {
          if (value.final_remark) return value.final_remark;
          else if (value.type_info == `PREENROLL`) return "New Patient";
        },
      },
    },
  }),

  methods: {
    getData() {
      if (this.datas.length > 0) return this.datas;
    },
  },
};
</script>

<style scoped>
.export_btn {
  background: #ff4081;
  border-radius: 3px;
  color: white !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 120px;
  padding: 5px 15px;
  margin-bottom: 1px;
}
</style>
