<template>
  <v-row>
    <v-col md="4">
      <label>Start Date</label>
      <date-picker
        @clearDate="startDate = null"
        :validationMsg="handleFieldErrors($v.startDate)"
        :date="startDate"
        v-model="startDate"
      />
    </v-col>
    <v-col md="4">
      <label>End Date</label>
      <date-picker
        @clearDate="endDate = null"
        :validationMsg="handleFieldErrors($v.endDate)"
        :date="endDate"
        v-model="endDate"
      />
    </v-col>
    <v-col class="mt-1" md="2">
      <br />
      <v-btn @click.prevent="emitDate" depressed dark color="teal accent-4">
        <v-icon left>mdi-magnify</v-icon>Search
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { ValidationMixin } from "../../../mixins/ValidationMixin";
import DatePicker from "@/components/common/DatePicker.vue";
export default {
  components: { DatePicker },
  mixins: [ValidationMixin],

  data() {
    return {
      startDate: "",
      endDate: "",
    };
  },
  validations: {
    startDate: { required },
    endDate: { required },
  },
  methods: {
    emitDate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("emitDate", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
      }
    },
  },
};
</script>
